.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.inputs {
  &-editor {
    @extend .form-control;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    &__toolbar {
      flex: 0 0 100%;
      .toolbar-thumb {
        font-size: 0.8rem;
        border: 1px solid #f1f1f1;
        margin-right: 5px;
      }
      .dropdown-menu {
        min-width: 8rem;
        .dropdown-item {
          font-size: 0.8rem;
          padding: 0.25rem;
        }
      }
    }
    &__area {
      flex: 0 0 100%;
      height: auto !important;
      padding: 0 20px;
      .DraftEditor-root {
        vertical-align: top;
        min-height: 250px;
      }
      h2 {
        color: $primary;
        // font-family: 'SansCond';
        font-size: 20px;
        font-weight: bold;
        line-height: 24px;
      }
      h3 {
        color: #000;
        // font-family: Gibson;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
      .rdw-image-imagewrapper {
        max-width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
}

.input {
  &--small {
    max-width: 227px;
  }
}

.heading {
  &--agenda {
    color: $primary;
    font-family: 'SansCond';
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
  }
  &--sub {
    color: #000;
    font-family: Gibson;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}

.react-datepicker__input-container {
  input:disabled {
    background-color: #e9ecef;
  }
}
