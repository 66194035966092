.leaflet-container {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.map {
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
