.editor {
  position: relative;
  @extend .form-control;
  max-width: 100%;
  padding: 0;

  .toolbar {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    // max-width: 100%;
    z-index: 100;
    padding: 5px;
    border-bottom: 1px solid #ced4da;
    display: flex;
    z-index: 5;
    background-color: #fff;
    border-radius: 5px;
    &-dropdown {
      border: none;
      &:hover {
        background-color: #c6c6c6;
      }
    }
  }

  &-toolbar-el {
    display: block;
    padding: 3px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3px;
    transition: $transition;
    text-align: center;

    &--active {
      color: $colorfunc1;
      background-color: $color1;
    }

    &:hover {
      color: #fff;
      background-color: #c6c6c6;
    }
  }

  &-wrapper {
    width: 100%;
    padding: 0.375rem 0.75rem;

    & > div {
      min-height: 300px !important;
    }

    img {
      max-width: 100%;
    }
  }

  &-textarea {
    border: none;
    resize: none;
    width: 100%;
    outline: 0;
    padding: 0;
    @extend .disclaimer;
  }

  .image-grid {
    display: flex;
    margin: 0 -10px;
    width: 100%;
    &__content {
      padding: 0 10px;
      &-img {
        position: relative;
        padding-top: 50%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    &-2 {
      .image-grid__content {
        flex: 0 0 50%;
      }
    }

    &-3 {
      .image-grid__content {
        flex: 0 0 33.3%;
      }
    }
  }

  .link {
    display: inline;
    position: relative;
    &-dropdown {
      min-width: 250px;
    }
    &-popup {
      position: absolute;
      left: 0;
      display: flex;
      align-items: center;
      background-color: white;
      padding: 6px 10px;
      gap: 10px;
      border-radius: 6px;
      border: 1px solid lightgray;
      width: fit-content;
    }
  }

  h2 {
    color: #000;
    font-family: 'Sans', Arial, sans-serif;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5em;
    margin: 1.5rem 0;
  }

  h3 {
    color: #000;
    font-family: 'Sans', Arial, sans-serif;
    font-size: 21px;
    font-weight: bold;
    line-height: 1.5em;
    margin: 1.5rem 0;
  }

  .disclaimer {
    color: #717171;
    font-size: 13px;
    font-style: italic;
    line-height: 20px;
  }

  .element-blockquote {
    width: 100%;

    blockquote {
      border: 1px solid #ddd;
      padding: 10px;
      padding-left: 30px;
      font-size: 14px;
      margin-bottom: 20px;
      font-style: italic;
    }

    &-author {
      display: flex;
      flex-flow: column;
      position: relative;
      padding-left: 80px;
      p {
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: contain;
      position: absolute;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../assets/empty-author.svg');
    }
  }

  .element-article-link {
    border: 1px solid #d6d6d6;
    padding: 24px 32px;
    margin-bottom: 18px;
    > div {
      margin-top: 14px;
      font-weight: bold;
    }
  }
}
