.ReactTable {
  background: $colorfunc1;
  .rt-table,
  .rt-tbody {
    overflow: hidden;
  }
}

.ReactTable {
  .rt-tbody,
  .rt-table {
    overflow-x: hidden !important;
  }
}
