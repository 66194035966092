.loading {
  min-height: calc(100vh - 57px);
  display: flex;
  align-items: center;
  justify-content: center;
  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(#fff, .6);
  }
}
