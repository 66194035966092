.prf-editor{
  padding-left: 0%;
  padding-right: 0%;

  &__title{
    font-size: 0.9rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &__image-grid{
    & > div{
      display: flex;
      gap: 16px;
      
    }
  }

  &__image-grid-item{
    
    aspect-ratio: 4/3;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    color: white;
    margin-bottom: 1rem;

    & > div{
      padding: 12px;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      label {
        font-size: 0.8rem;
        font-weight: 700;
        color: white;
      }
    }
  }
}