$hightlight-color: rgba(0, 0, 0, 0.125);

.categories-input-wrapper {
  .categories-input {
    background: #fff;
    @extend .form-control;
    height: auto;
    margin-bottom: 10px;
    min-height: 53px;
  }
  .categories-input-search {
    position: relative;
    z-index: 2;
  }
  .categories-list {
    max-height: 200px;
    overflow: auto;
    border: 1px solid $hightlight-color;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    position: relative;
    z-index: 0;
    margin-top: -2px;
    padding-top: 2px;
    &-el {
      &--sub {
        padding-left: 25px!important;
      }
    }
    .list-group-item {
      padding: 5px 10px;
      border: none;
      border-bottom: 1px solid $hightlight-color;
      margin-bottom: 0;
      cursor: pointer;
      &:hover {
        background-color: $hightlight-color;
      }
      &:first-child {
        border-radius: 0;
      }
    }
  }
}
