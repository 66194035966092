.Quiz {
  .Quiz__info {
    position: sticky;
    top: 20px;
  }

  .Quiz__info__actions {
    display: flex;
    justify-content: space-between;
  }

  &__highlight_image {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
  }
}

.Quiz__error {
  color: red;
  font-weight: 700;
  margin-top: 1rem;
  text-transform: uppercase;
}
