.QuizAnswers {
  &__answer {
    margin-bottom: 1.5rem;
  }
  &__answer_text {
    font-size: 1.25rem;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 100%;

    &:hover:not(:disabled) {
      border: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  &__answer__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    svg {
      margin-left: 1rem;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
