.form {
  &-hide {
    overflow: hidden;
    max-height: 0px;
    transition: all 0.5s ease-in-out;
    &--media {
      &.on {
        max-height: 500px;
      }
    }
  }
}

.field-length {
  display: block;
  margin-top: -1rem;
  text-align: right;
}
