.QuizQuestions {
  &__question {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }

  &__question_multi_choice {
    margin-bottom: 0.5rem;
  }

  &__question_text {
    font-size: 1.5rem;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    width: 100%;

    &:hover:not(:disabled) {
      border: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  &__actions {
    margin-top: 2rem;
  }

  &__question__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    svg {
      margin-left: 1rem;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
