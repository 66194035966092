.VideosPreview {
  &__playback {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    margin-bottom: 1rem;
    background-color: #00965e;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border: none;
    }
  }

  &__noVideo {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    svg {
      fill: white;
      width: 5rem;
      height: 5rem;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}
