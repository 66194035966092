.ReactTable {
  .rt-tbody {
    .rt-tr {
      cursor: pointer;
      &.active {
        background-color: #00965e !important;
        color: white !important;
      }
    }
  }
}
