.files {
  &-item {
    &__preview {
      display: block;
      width: 100px;
      height: 100px;
      margin-right: 10px;
      margin-bottom: 5px;
      background: $subcolor2;
      border-radius: 5px;
      overflow: hidden;
      &--img {
        object-fit: contain;
      }
      &--ext {
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-row {
        display: flex;
      }
    }
    &__data {
      margin-bottom: 5px;
    }
  }
  &-dropzone {
    height: auto;
    border: 1px solid var(--gray);
    background: var(--white);
    border-radius: 0.25rem;
    padding: 2rem;
    p {
      margin-bottom: 0;
    }
    .list-group {
      padding: 0.5rem 0;
    }
  }
}
