@import './variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~leaflet/dist/leaflet.css';

body {
  background-color: $subcolor1;
}
//
@import './components/loading.scss';
@import './components/navbar.scss';
@import './components/maincontainer.scss';
@import './components/sidebar.scss';
@import './components/tables.scss';
@import './components/article.scss';
@import './components/editor.scss';
@import './components/categories.scss';
@import './components/buttons.scss';
@import './components/modal.scss';
@import './components/listItem.scss';
@import './components/file.scss';
@import './components/form.scss';
@import './components/inputs.scss';
@import './components/maps.scss';
@import './components/datepicker.scss';
@import './components/card.scss';
@import './components/footer.scss';
@import './components/prf.scss';
@import './utilities/link.scss';

main {
  padding-bottom: 100px;
}

@font-face {
  font-family: 'Sans';
  src: url('../assets/fonts/BNPPSans/BNPPSans-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Sans';
  src: url('../assets/fonts/BNPPSans/BNPPSans-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sans';
  src: url('../assets/fonts/BNPPSans/BNPPSans-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'SansCond';
  src: url('../assets/fonts/BNPPSansCond/BNPPSansCond-300.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'SansCond';
  src: url('../assets/fonts/BNPPSansCond/BNPPSansCond-400.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SansCond';
  src: url('../assets/fonts/BNPPSansCond/BNPPSansCond-700.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}
