.Quizzes {
  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    padding: 0 !important;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
  }
}
