.navbar {
  background: $colorfunc1;
  position: relative;
  border-bottom: 1px solid $subcolor2;
  &-brand {
    font-weight: bold;
    color: $color1;
  }
}

.nav {
  &-link {
    font-weight: bold;
    &--active {
      color: #00965e;
    }
  }
}
