.footer {
  position: absolute;
  bottom: 0;
  right: 15px;
  left: 15px;
  &-logo {
    display: block;
    img {
      max-width: 120px;
    }
  }
}
