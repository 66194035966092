.media-card {
  img {
    width: 100%;
    height: auto;
  }

  border-bottom: 1px solid black;
  margin-bottom: 8px;
  margin-top: 8px;

  h6,
  p {
    word-break: break-all;
  }

  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}
