.listItem {
  &--with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-top {
      align-items: flex-start;
    }
    .types {
      &--icon {
        max-width: 20px;
      }
    }
  }
}

.list-group {
  &--errors {
    .list-group-item {
      padding: 0.4rem;
    }
  }
}
