$color1: #00965e;
$color2: #ffa203;
$color3: #2e3033;

$subcolor1: #ececec;
$subcolor2: #9b9b9b;
$subcolor3: #e92b5c;

$colorfunc1: #fff;

$transition: all 0.3s ease-in-out;

// change bootstrap variables;
$primary: $color1;
$navbar-padding-x: 1rem;
